import * as React from 'react'
import Layout from '../components/layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'


const Page404 = () => {
  return (
      <div className='p-4 shadow-md w-full'>
        <p>Error 404. Sidan kunde inte hittas.</p>
        <Link className='text-blue-700' to='/'>Hem</Link>
      </div>
  )
}

export default Page404